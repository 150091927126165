const initialState = {
  myRestaurants: [],
  restaurant: null,
  selectedMenu: null,
  selectedMeal: null,
};

export const UPDATE_SELECTED_MENU = 'UPDATE_SELECTED_MENU';
export const UPDATE_RESTAURANT = 'UPDATE_RESTAURANT';
export const UPDATE_MY_RESTAURANTS = 'UPDATE_MY_RESTAURANTS';

const restaurantModule = {
  namespaced: true,
  state: initialState,
  actions: {
    updateSelectedRestaurant({ commit }, { restaurant }) {
      commit(UPDATE_RESTAURANT, { restaurant });
    },
    updateSelectedMenu({ commit }, { menu }) {
      commit(UPDATE_SELECTED_MENU, { menu });
    },
    updateMyRestaurants({ commit }, { restaurants }) {
      commit(UPDATE_MY_RESTAURANTS, { restaurants });
    },
  },
  mutations: {
    [UPDATE_RESTAURANT](state, { restaurant }) {
      state.restaurant = restaurant;
    },
    [UPDATE_SELECTED_MENU](state, { menu }) {
      state.selectedMenu = menu;
    },
    [UPDATE_MY_RESTAURANTS](state, { restaurants }) {
      state.myRestaurants = restaurants;
    },
  },
  getters: {
    restaurant: (state) => state.restaurant,
    myRestaurants: (state) => state.myRestaurants,
    menus: (state) => state.menus,
    selectedMenu: (state) => state.selectedMenu,
    selectedMeal: (state) => state.selectedMeal,
  },
};

export default restaurantModule;