<template>

  <div class="container
              page-container
              is-flex
              is-flex-wrap-wrap
              is-justify-content-space-evenly
              is-align-items-center">

    <MarketingList />

    <SignInForm />

  </div>

</template>

<script>
import MarketingList from '@/components/shared/MarketingList.vue';
import SignInForm from '@/components/shared/SignInForm.vue';

export default {
  components: {
    MarketingList,
    SignInForm,
  },
};
</script>

<style scoped lang="scss">
  .page-container {
    min-height: calc(100vh - 162px);
  }
</style>