<template>

  <div class="container
              page-container
              is-flex
              is-flex-wrap-wrap
              is-justify-content-space-evenly
              is-align-items-center">
    <MarketingList />
    <SignUpForm />
  </div>

</template>

<script>
import SignUpForm from '@/components/shared/SignUpForm.vue';
import MarketingList from '@/components/shared/MarketingList.vue';

export default {
  components: {
    SignUpForm,
    MarketingList,
  },
};
</script>

<style scoped lang="scss">
  .page-container {
    min-height: calc(100vh - 162px);
  }
  ::v-deep label {
    font-weight: 400 !important;
  }
</style>