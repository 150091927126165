const initialState = {
  // refresh variables
  refreshing: false,
  oldSwState: null,
  registration: null,
  updateExists: false,
  isRefreshNotification: false,
};

export const INITIALIZE_SW = 'INITIALIZE_SW';
export const UPDATE_AVAILABLE = 'UPDATE_AVAILABLE';
export const CONTROLLER_CHANGE = 'CONTROLLER_CHANGE';
export const REFRESH_APP = 'REFRESH_APP';
export const SET_REFRESH_NOTIFICATION = 'SET_REFRESH_NOTIFICATION';
export const SET_OLD_SW_STATE = 'SET_OLD_SW_STATE';

const swModule = {
  namespaced: true,
  state: initialState,
  actions: {
    async initializeSw({ commit }) {
      // Listen for our custom event from the SW registration
      document.addEventListener(
        'swUpdated',
        (event) => commit(UPDATE_AVAILABLE, { event }),
        { once: true },
      );

      if (navigator.serviceWorker) {
        // Prevent multiple refreshes
        const oldSwState = (await navigator.serviceWorker.getRegistration())?.active?.state;

        commit('SET_OLD_SW_STATE', { value: oldSwState });

        navigator.serviceWorker.addEventListener(
          'controllerchange',
          () => commit('CONTROLLER_CHANGE'),
        );
      }
    },
    refreshApp({ commit }) {
      commit(REFRESH_APP);
    },
    setRefreshNotification({ commit }, { value }) {
      commit(SET_REFRESH_NOTIFICATION, { value });
    },
  },
  mutations: {
    // Store the SW registration so we can send it a message
    // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
    // To alert the user there is an update they need to refresh for
    [UPDATE_AVAILABLE](state, { event }) {
      state.registration = event.detail;
      state.updateExists = true;
    },
    async [CONTROLLER_CHANGE](state) {
      if (state.refreshing) return;

      const newSwState = (await navigator.serviceWorker.getRegistration())?.active?.state;

      if (state.oldSwState === 'activated' && newSwState === 'activating') {
        state.refreshing = true;
        // Here the actual reload of the page occurs
        window.location.reload();
      }
    },
    // Set notification state -> close it if value is false
    [SET_OLD_SW_STATE](state, { value }) {
      state.oldSwState = value;
    },
    // Set notification state -> close it if value is false
    [SET_REFRESH_NOTIFICATION](state, { value }) {
      state.isRefreshNotification = value;
    },
    [REFRESH_APP](state) {
      state.updateExists = false;
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!state.registration || !state.registration.waiting) return;
      // send message to SW to skip the waiting and activate the new SW
      state.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    },
  },
  getters: {
    refreshing: (state) => state.refreshing,
    registration: (state) => state.registration,
    updateExists: (state) => state.updateExists,
    isRefreshNotification: (state) => state.isRefreshNotification,
  },
};

export default swModule;