<template>

  <form @submit.prevent="recaptcha" style="width: 35%; min-width: 300px;">

    <div class="mb-6 has-text-centered">
      <p class="">Créer un compte restaurateur</p>
    </div>

    <b-field
      label="Email"
      custom-class="is-small has-text-weight-light has-text-grey"
      :type="{ 'is-danger': $v.form.email.$error }"
      :message="[
        { 'Email non valide': !$v.form.email.email },
        { 'Champs requis': !$v.form.email.required && $v.form.email.$dirty },
      ]"
    >
      <b-input
        v-model.trim="$v.form.email.$model"
        placeholder="Email"
        type="email"
        ref="emailInput"
        @keyup.enter.native="signup"
        rounded
      />
    </b-field>

    <b-field
      label="Mot de passe (min 10 caractères)"
      custom-class="is-small has-text-weight-light has-text-grey"
      :type="{ 'is-danger': $v.form.password.$error }"
      :message="[
        { 'Minimum 10 caractères': !$v.form.password.minLength },
        { 'Champs requis': !$v.form.password.required && $v.form.password.$dirty },
      ]"
    >
      <b-input
        type="password"
        v-model.trim="$v.form.password.$model"
        password-reveal
        @keyup.enter.native="signup"
        rounded
      />
    </b-field>

    <b-field
      label="Confirmation mot de passe"
      custom-class="is-small has-text-weight-light has-text-grey"
      :type="{ 'is-danger': $v.form.passwordConfirmation.$error }"
      :message="[
        {
          'Les deux mots de passe doivent être identiques':
            $v.form.passwordConfirmation.$error
        },
      ]"
    >
      <b-input
        type="password"
        v-model.trim="$v.form.passwordConfirmation.$model"
        password-reveal
        @keyup.enter.native="signup"
        rounded
      />
    </b-field>

    <b-field class="mt-5 has-text-left is-size-7">
      <b-checkbox v-model="$v.form.termsOfService.$model" type="is-secondary">
        J'accepte les
        <a :href="landingUrl" target="_blank">conditions générales d'utilisation</a>
      </b-checkbox>
    </b-field>

    <b-notification
      type="is-dark"
      aria-close-label="Fermer message d'erreur"
      role="alert"
      closable
      class="mt-4"
      v-model="isInfoMsgActive">
      {{ error }}
    </b-notification>

    <div>

      <b-button
        label="Créer un compte"
        type="is-primary"
        expanded
        class="is-primary mb-4 mt-6"
        rounded
        native-type="submit"
        icon-left="rocket-launch"
      />

      <b-button
        label="Se connecter"
        tag="router-link"
        to="/signin"
        expanded
        rounded
        outlined
      />

    </div>

    <b-loading v-model="isLoading" />

  </form>

</template>

<script>
import {
  required,
  sameAs,
  minLength,
  email,
  maxLength,
} from 'vuelidate/lib/validators';

export default {
  data: () => ({
    form: {
      email: '',
      password: '',
      passwordConfirmation: '',
      comChannel: '',
      improvementOpportunities: [],
      otherOpportunity: '',
      job: '',
      otherJob: '',
      termsOfService: false,
    },
    isLoading: false,
    isInfoMsgActive: false,
    hasVisiblePassword1: false,
    hasVisiblePassword2: false,
    isEmailValidationActive: false,
    isPasswordValidationActive: false,
    isPasswordConfirmationValidationActive: false,
    areRulesDisplayed: false,
    landingUrl: process.env.VUE_APP_LANDING_URL,
    error: '',
  }),
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
        maxLength: maxLength(64),
        minLength: minLength(10),
      },
      passwordConfirmation: {
        sameAsPassword: sameAs('password'),
      },
      termsOfService: {
        isTrue: (value) => value,
      },
    },
  },
  mounted() {
    this.$refs.emailInput.focus();
  },
  computed: {
    getSecurePasswordProgress() {
      let progress = 0;

      // at least one number
      if (/\d/.test(this.form.password)) progress += 20;
      // at least one capital letter
      if (/(.*[A-Z].*)/.test(this.form.password)) progress += 20;
      // at menons a lowercase
      if (/(.*[a-z].*)/.test(this.form.password)) progress += 20;
      // more than 10 character
      if (this.form.password.length >= 10) progress += 20;
      // at least one special character
      if (/[^A-Za-z0-9]/.test(this.form.password)) progress += 20;

      return progress;
    },
  },
  methods: {
    async recaptcha() {
      try {
        this.isLoading = true;
        // (optional) Wait until recaptcha has been loaded.
        await this.$recaptchaLoaded();

        // Execute reCAPTCHA with action "login".
        // createAccount landing
        const token = await this.$recaptcha('signup');

        // Verify challenge with received token.
        await this.$http.post(`${process.env.VUE_APP_API_URL}/captchas/verify`, { token });
        // Recaptcha success, let's login.
        this.signup();
      } catch (error) {
        this.$buefy.snackbar.open({
          indefinite: true,
          type: 'is-danger',
          message: 'Une erreur est survenue.',
        });
        this.isLoading = false;
      }
    },
    async signup() {
      const payload = this.form;

      this.isInfoMsgActive = false;
      try {
        await this.$http.post(
          `${process.env.VUE_APP_API_URL}/auth/signup/restaurant`, payload,
        );

        this.$router.push({ name: 'SignIn' });
        this.$buefy.snackbar.open({
          message: `
            Un email de confirmation vous a été envoyé. <br>
            Veuillez consulter votre messagerie et cliquez sur le lien d'activation. <br>
            L'envoi de l'email peut prendre quelques minutes,
            si vous ne recevez pas le mail après ce délais vérifier votre dossier "courriers indésirables".
          `,
          position: 'is-bottom',
          type: 'is-success',
          indefinite: true,
          actionText: 'ok',
        });
      } catch (err) {
        const { status } = err.response;
        let message = '';

        switch (status) {
          case 400:
            message = err.response.data.message;
            break;
          case 409:
            message = 'Email déjà utilisé.';
            break;
          default:
            message = 'Email ou mot de passe incorrect.';
        }

        this.error = message;
        this.isInfoMsgActive = true;
      } finally { this.isLoading = false; }
    },
  },
};
</script>

<style lang="scss" scoped>
  .form {
    ::v-deep label {
      font-weight: 400 !important;
    }
  }
</style>