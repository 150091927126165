import axios from 'axios';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';

const API_URL = `${process.env.VUE_APP_API_URL}/auth`;

export const setSession = (data) => {
  localStorage.setItem('user', JSON.stringify(data));
};

export const authSignin = async (user) => {
  const res = await axios.post(`${API_URL}/signin`, {
    email: user.email,
    password: user.password,
  });

  setSession(res.data);
  return res;
};

export const logout = () => {
  localStorage.removeItem('user');
};

export const isLogedIn = () => {
  const data = JSON.parse(localStorage.getItem('user'));

  if (!Date.now) {
    // for IE 8
    Date.now = () => new Date().getTime();
  }
  if (data) {
    const decoded = jwt_decode(data.token);
    const { exp } = decoded;
    // eslint-disable-next-line no-bitwise
    const currentTimestamp = Date.now() / 1000 | 0;

    // if token exp timestamp < current timestamp token is expired
    return exp > currentTimestamp;
  }
  return false;
};

export const getUser = () => (
  JSON.parse(localStorage.getItem('user'))
);

export const isUserAuthorized = (requiredRoles) => {
  const userData = getUser();
  const currentUserRoles = userData?.user?.role ? [userData.user.role] : [];

  if (!requiredRoles || requiredRoles.length === 0) {
    return true;
  }
  if (!currentUserRoles || currentUserRoles.length === 0) {
    return false;
  }
  return requiredRoles.some((role) => currentUserRoles.indexOf(role) > -1);
};