<template>

  <div class="is-flex is-flex-direction-column">

    <div class="is-flex
                is-flex-wrap-wrap
                is-justify-content-center
                is-align-items-center">

      <div style="max-width: 80px;">
        <b-image alt="cookie menu app logo" :src="logoImage" class="m-0" />
      </div>
      <div class="p-5">
        <p class="has-text-grey is-size-5 is-size-6-mobile mb-1">
          Bienvenue sur
          <span class="has-text-weight-semibold has-text-dark">Cookie.menu</span>
        </p>
        <p class="has-text-grey-light is-size-6">Espace restaurateur</p>
      </div>

    </div>

    <div class="mt-6">
      <div v-for="item in marketingItems" :key="item" class="is-flex mb-1">
        <b-icon icon="check" type="is-success" class="mr-2" />
        <p class="mb-0 is-size-6" style="line-height: 1.5em;">
          {{ item }}
        </p>
      </div>
    </div>

  </div>

</template>

<script>
import logoImage from '@/assets/img/Logo-rond-jaune-fond-transp.png';

export default {
  data: () => ({
    logoImage,
    marketingItems: [
      'Gagner du temps en salle',
      'Fidéliser vos clients',
      'Protéger votre établissement du virus',
    ],
  }),
};
</script>