/* eslint-disable dot-notation */
/* eslint-disable no-param-reassign */
/* eslint-disable quote-props */
import axios from 'axios';
import router from '../router';
import store from '../store';

const axiosInstance = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 10000,
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const token = store.getters['authModule/token'];

    if (token) {
      config.headers.common['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if ([401, 403].indexOf(error.response?.status) !== -1) {
      store.dispatch('authModule/logout');
      store.dispatch('authModule/updateHasSessionExpired', { value: true });
      // eslint-disable-next-line
      console.log('User session expired');
      router.push({ name: 'SignIn' });
    }
    if ([429].indexOf(error.response?.status) !== -1) {
      // eslint-disable-next-line
      console.log('Number of requests exceeded limit, please try again after a minute');
    }
    throw error;
  },
);

export default axiosInstance;