import Vue from 'vue';
import Vuex from 'vuex';
import authModule from './auth.module';
import restaurantModule from './restaurant.module';
import swModule from './sw.module';

Vue.use(Vuex);

export const UPDATE_TRANSITION_NAME = 'UPDATE_TRANSITION_NAME';
export const UPDATE_FOOTER_DISPLAYED_VALUE = 'UPDATE_FOOTER_DISPLAYED_VALUE';
export const UPDATE_COVID_HAS_BEEN_DISPLAYED = 'UPDATE_COVID_HAS_BEEN_DISPLAYED';

export default new Vuex.Store({
  state: {
    transitionName: 'slide-right',
    isFooterDisplayed: true,
    hasCovidFormAlreadyBeenDisplayed: false,
  },
  mutations: {
    [UPDATE_TRANSITION_NAME](state, { transitionName }) {
      state.transitionName = transitionName;
    },
    [UPDATE_FOOTER_DISPLAYED_VALUE](state, { value }) {
      state.isFooterDisplayed = value;
    },
    [UPDATE_COVID_HAS_BEEN_DISPLAYED](state, { value }) {
      state.hasCovidFormAlreadyBeenDisplayed = value;
    },
  },
  actions: {
    updateTransitionName({ commit }, { transitionName }) {
      commit(UPDATE_TRANSITION_NAME, { transitionName });
    },
    updateFooterDisplayedValue({ commit }, { value }) {
      commit(UPDATE_FOOTER_DISPLAYED_VALUE, { value });
    },
    updateCovidHasBeenDisplayed({ commit }, { value }) {
      commit(UPDATE_COVID_HAS_BEEN_DISPLAYED, { value });
    },
  },
  getters: {
    transitionName: (state) => state.transitionName,
    isFooterDisplayed: (state) => state.isFooterDisplayed,
    hasCovidFormAlreadyBeenDisplayed: (state) => state.hasCovidFormAlreadyBeenDisplayed,
  },
  modules: {
    authModule,
    restaurantModule,
    swModule,
  },
});