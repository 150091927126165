<template>

  <b-navbar
    type="is-white"
    transparent
    shadow
    class="navbar_container"
  >

    <template #brand>
      <b-navbar-item tag="router-link" to="/signup">
        <img
          alt="Cookie app logo"
          src="@/assets/img/logo-web-transparent.png"
          class="logo"
        >
      </b-navbar-item>
    </template>

    <template #end>

      <b-navbar-item tag="div">

        <b-navbar-item tag="div" v-if="!isLogedIn" class="right_container">

          <div class="buttons">
            <b-button
              label="Espace restaurateur"
              class="has-text-grey-dark"
              tag="router-link"
              outlined
              to="/signin"
              icon-left="login"
              rounded
              v-if="!showBtn(['SignIn', 'SignUp'])"
            />
          </div>
        </b-navbar-item>

        <div v-if="isLogedIn" class="right_container">

          <b-dropdown
            position="is-bottom-left"
            append-to-body
            aria-role="list"
            :triggers="['hover', 'click']"
          >

            <template #trigger>
              <a class="navbar-item" role="button">
                <figure class="image is-24x24">
                  <img class="is-rounded" alt="User avatar" :src="avatarUrl">
                </figure>
                <b-icon icon="menu-down"></b-icon>
              </a>
            </template>

            <b-dropdown-item custom aria-role="listitem">
              Connecté en tant que
              <span class="has-text-weight-semibold">{{ user && user.email }}</span>
            </b-dropdown-item>

            <hr class="dropdown-divider">

            <b-dropdown-item aria-role="listitem">
              <div
                class="is-flex is-align-items-center"
                @click="($route.name !== 'BO_Home') && $router.push({ name: 'BO_Home' })"
              >
                <b-icon icon="storefront" class="mr-2" />
                Mes établissements
              </div>
            </b-dropdown-item>

            <hr class="dropdown-divider" aria-role="listitem">

            <b-dropdown-item aria-role="listitem">
              <div class="is-flex is-align-items-center" @click="logout">
                <b-icon icon="logout" type="is-warning" class="mr-2" />
                Se déconnecter
              </div>
            </b-dropdown-item>

          </b-dropdown>

        </div>

      </b-navbar-item>

    </template>

  </b-navbar>

</template>

<script>
import md5 from 'md5';

export default {
  data: () => ({
    navigation: '',
  }),
  methods: {
    logout() {
      this.$store.dispatch('authModule/logout');
      this.$router.push({ name: 'SignIn' });
    },
    showBtn(routeNames) {
      return this.$route.matched.some(({ name }) => (
        routeNames.some((n) => name === n)
      ));
    },
  },
  computed: {
    user() {
      return this.$store.getters['authModule/user'];
    },
    isLogedIn() {
      return this.$store.getters['authModule/isLoggedIn'];
    },
    avatarUrl() {
      const email = this.user?.email;
      const hash = email ? md5(email.trim().toLowerCase()) : '';
      // d=mp
      const avatarUrl = `https://www.gravatar.com/avatar/${hash}?d=identicon`;

      return avatarUrl;
    },
  },
};
</script>

<style scoped lang="scss">
  .navbar_container {
    height: 44px;
  }
  .right_container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ::v-deep .navbar-brand {

    .navbar-item {
      padding: 0;
    }
    img {
      max-height: 3rem !important;
    }
    .logo {
      cursor: pointer;
    }
  }
</style>