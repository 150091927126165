<template>

  <footer class="footer pt-5 pb-6 has-background-black-bis has-text-white">

    <div class="container">

      <div class="columns">

        <div class="column is-two-fifths">

          <div class="is-flex is-clickable" @click="goToLandingPage">
            <div style="max-width: 100px;">
              <b-image
                :src="require('@/assets/img/logo-web-transparent-white.png')"
                alt="Cookie Menu logo"
                class="m-0"
              />
            </div>
            <div class="p-5">
              <p class="is-size-5 is-size-6-mobile mb-1">
                Cookie <span style="color: rgb(183, 135, 45);">Menu</span>
              </p>
              <p class="has-text-grey-lighter is-size-6">Menu digital gratuit</p>
            </div>
          </div>

          <div class="mt-4 mb-6">
            <b-field grouped group-multiline>

              <div class="mr-2">
                <b-taglist attached>
                  <b-tag type="is-dark">app</b-tag>
                  <b-tag type="is-light">{{ clientVersion }}</b-tag>
                </b-taglist>
              </div>

              <div>
                <b-taglist attached>
                  <b-tag type="is-dark">api</b-tag>
                  <b-tag type="is-light">{{ apiVersion }}</b-tag>
                </b-taglist>
              </div>

            </b-field>
          </div>

        </div>

        <div class="column columns">

          <div class="column p-5">

            <p class="has-text-weight-medium pb-3">Découvrir Cookie Menu</p>

            <ul>
              <li class="mb-1">
                <a :href="`${landingUrl}`"
                  class="has-text-grey-lighter underline"
                  target="_blank">
                  Notre offre
                </a>
              </li>
              <li class="mb-1">
                <a :href="`${landingUrl}apropos.html`"
                  class="has-text-grey-lighter underline"
                  target="_blank">
                  À propos
                </a>
              </li>
              <li class="mb-1">
                <a :href="`${landingUrl}actualites.html`"
                  class="has-text-grey-lighter underline"
                  target="_blank">
                  Actualités
                </a>
              </li>
              <li class="mb-1">
                <a :href="`${appUrl}/signin`"
                  class="has-text-grey-lighter underline"
                  target="_blank">
                  Inscription Restaurateur
                </a>
              </li>
              <li class="mb-1">
                <p @click="share"
                  class="has-text-grey-lighter underline is-clickable">
                  Partager
                </p>
              </li>
            </ul>

          </div>

          <div class="column p-5">

            <p class="has-text-weight-medium pb-3">Mentions légales</p>

            <ul>
              <li class="mb-1">
                <a :href="`${landingUrl}cgu.html`"
                  class="has-text-white-ter underline"
                  target="_blank">
                  Conditions générales d'utilisation
                </a>
              </li>
            </ul>

          </div>

          <div class="column p-5">

            <p class="has-text-weight-medium pb-3">Aider</p>

            <ul>
              <li class="mb-1">
                <a :href="hrefContact" target="_blank" class="has-text-white underline">
                  Contacter Cookie Menu
                </a>
              </li>
              <li class="mb-1">
                <a :href="landingUrl + '#faq'"
                   class="has-text-white underline"
                   target="_blank">
                  FAQ
                </a>
              </li>
            </ul>
          </div>

        </div>

      </div>

      <div class="is-size-7 has-text-right">
        Ce site est protégé par reCAPTCHA.
        Par ailleurs,
        la <a href="https://policies.google.com/privacy">Politique de confidentialité</a> et les <a href="https://policies.google.com/terms">Conditions d'utilisation</a> de Google s'appliquent.
       </div>

      <hr style="border-top: 1px solid white;">

      <div class="is-flex
                  is-justify-content-space-between
                  is-align-items-center
                  is-size-7">
        <div>
          <a href="https://www.facebook.com/GetCookieMenu/?ref=page_internal"
             class="has-text-white mr-3"
             target="_blank">
            <b-icon icon="facebook" class="facebook-icon is-clickable"/>
          </a>
          <a href="https://www.linkedin.com/company/cookie-menu/"
             class="has-text-white"
             target="_blank">
            <b-icon icon="linkedin" class="linkedin-icon is-clickable"/>
          </a>
        </div>

        <div>
          <p>© {{ year }} - Cookie Menu - All right reserved</p>
        </div>
      </div>
    </div>

  </footer>

</template>

<script>
export default {
  data: () => ({
    clientVersion: process.env.VUE_APP_VERSION,
    appUrl: process.env.VUE_APP_BASE_URL,
    landingUrl: process.env.VUE_APP_LANDING_URL,
    apiVersion: '',
    hrefContact: "mailto:hello@cookie.menu?subject=Demande%20d'informations",
    year: new Date().getFullYear(),
  }),
  created() {
    this.fetchVersion();
  },
  methods: {
    goToLandingPage() {
      window.open(process.env.VUE_APP_LANDING_URL, '_blank');
    },
    async fetchVersion() {
      this.active = false;
      try {
        const res = await this.$http.get(
          `${process.env.VUE_APP_API_URL}/versions`,
        );

        this.apiVersion = res?.data?.api_version;
      } catch (err) {
        // eslint-disable-next-line
        console.log(err);
      }
    },
    async share() {
      if (navigator.share) {
        try {
          await navigator.share({
            url: this.landingUrl,
            title: 'Cookie Menu',
            text: 'Hello\n\nVoici un super outil de menu QR Code gratuit  ➡️ https://www.cookie.menu 🍪👩‍🍳\n\nBonne journée',
          });
        } catch (err) {
          // eslint-disable-next-line
          console.error('Share failed:', err.message);
        }
      } else {
        // fallback for navigators that doesn't support share API
        window.open('mailto:?subject=Cr%C3%A9ation%20de%20menu%20digital&body=Hello%0A%0AVoici%20un%20super%20outil%20de%20menu%20QR%20Code%20gratuit%20%20%E2%9E%A1%EF%B8%8F%20https%3A%2F%2Fwww.cookie.menu%20%F0%9F%8D%AA%F0%9F%91%A9%E2%80%8D%F0%9F%8D%B3%0A%0ABonne%20journ%C3%A9e', '_blank');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .facebook-icon {

    &:hover {
      color: #4765a8;
    }
  }
  .linkedin-icon {

    &:hover {
      color: #2966bc;
    }
  }
  .underline {

    &:hover {
      text-decoration: underline;
    }
  }
</style>
