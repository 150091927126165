import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import { isUserAuthorized } from '../services/auth.service';

import SignIn from '../views/shared/SignIn.vue';
import SignUp from '../views/shared/SignUp.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/bo/restaurants/:restaurantId',
    component: () => import('../views/back_office/Restaurant.vue'),
    children: [
      {
        path: 'general',
        component: () => import('@/components/back_office/RestaurantGeneral.vue'),
        name: 'BO_RestaurantGeneral',
        meta: {
          requiresAuth: true,
          roles: ['restaurant_owner', 'admin'],
        },
      },
      {
        path: 'qrcode',
        component: () => import('@/components/back_office/RestaurantQrCode.vue'),
        name: 'BO_RestaurantQrCode',
        meta: {
          requiresAuth: true,
          roles: ['restaurant_owner', 'admin'],
        },
      },
      {
        path: 'menu',
        component: () => import('@/components/back_office/MenuCreator.vue'),
        name: 'BO_RestaurantMenu',
        meta: {
          requiresAuth: true,
          roles: ['restaurant_owner', 'admin'],
        },
      },
    ],
    meta: {
      requiresAuth: true,
      roles: ['restaurant_owner', 'admin'],
    },
  },
  // BO restaurant owner: Home page
  {
    path: '/bo/restaurants',
    name: 'BO_Home',
    component: () => import('../views/back_office/Home.vue'),
    meta: {
      requiresAuth: true,
      roles: ['restaurant_owner', 'admin'],
    },
  },
  // Customer: Restaurant/Menu/Infos page
  {
    path: '/restaurants/:restaurantId',
    name: 'Restaurant',
    component: () => import('../views/client/Restaurant.vue'),
    meta: {
      requiresAuth: false,
      roles: [],
    },
  },
  // Customer: Restaurant/Menu/Infos page (by alias)
  {
    path: '/site/:restaurantId',
    name: 'RestaurantByAlias',
    component: () => import('../views/client/Restaurant.vue'),
    meta: {
      requiresAuth: false,
      roles: [],
    },
  },
  // Customer: QR code reader page
  {
    path: '/scan',
    name: 'QrCodeReader',
    component: () => import('../views/client/Scan.vue'),
    meta: {
      requiresAuth: false,
      roles: [],
    },
  },
  // BO restaurant owner: Sign in page
  {
    path: '/signin',
    name: 'SignIn',
    component: SignIn,
    meta: {
      requiresAuth: false,
      roles: [],
    },
    beforeEnter: (to, from, next) => {
      if (store.getters['authModule/isLoggedIn']) {
        next({ name: 'BO_Home' });
      } else {
        next();
      }
    },
  },
  // BO restaurant owner: Sign up page
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp,
    meta: {
      requiresAuth: false,
      roles: [],
    },
    beforeEnter: (to, from, next) => {
      if (store.getters['authModule/isLoggedIn']) {
        next({ name: 'BO_Home' });
      } else {
        next();
      }
    },
  },
  // BO restaurant owner: Reset password page
  {
    path: '/password/reset',
    name: 'PasswordReset',
    component: () => import('../views/client/PasswordReset.vue'),
    meta: {
      requiresAuth: false,
      roles: [],
    },
  },
  // BO restaurant owner: verify password page
  {
    path: '/password/verify',
    name: 'PasswordVerify',
    component: () => import('../views/client/PasswordVerify.vue'),
    meta: {
      requiresAuth: false,
      roles: [],
    },
  },
  // BO restaurant owner: verify account page (access from email signup link)
  {
    path: '/account/verify',
    name: 'AccountVerification',
    component: () => import('../views/client/AccountVerification.vue'),
    meta: {
      requiresAuth: false,
      roles: [],
    },
  },
  {
    path: '/not-found',
    name: 'PageNotFound',
    component: () => import('../views/PageNotFound.vue'),
    meta: {
      requiresAuth: false,
      roles: [],
    },
  },
  {
    path: '/',
    redirect: 'SignIn',
  },
  {
    path: '*',
    redirect: { name: 'PageNotFound' },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to) => {
    if (to.hash) {
      // avoid strange jump on customer page after click on menu anchors
      return {};
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  // if route required authentification
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // if user is not already authenticated redirect to login page
    if (!store.getters['authModule/isLoggedIn']) {
      next({
        path: '/signin',
        query: { redirectUrl: to.fullPath },
      });
    } else {
      const requiredRoles = to.meta.roles || [];
      const isAuth = isUserAuthorized(requiredRoles);

      // if user doesn't have required roles redirect to home page
      if (!isAuth) {
        next({ name: from.name || 'SignIn' });
      } else {
        next();
      }
    }
  } else {
    // page can be accessed by anyone
    next();
  }
});

router.beforeEach((to, from, next) => {
  const toDepth = to.path.split('/').length;
  const fromDepth = from.path.split('/').length;
  const transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left';

  store.dispatch('updateTransitionName', { transitionName });
  next();
});

export default router;