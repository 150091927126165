/* eslint-disable global-require */
import Vue from 'vue';
import Vuelidate from 'vuelidate';
import Buefy from 'buefy';
import VueSmoothScroll from 'vue2-smooth-scroll';
import VueRellax from 'vue-rellax';
import VueLazyload from 'vue-lazyload';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import VueTour from 'vue-tour';
import axiosInstance from './helpers/axios.config';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

import 'reset-css';
import 'leaflet/dist/leaflet.css';
import 'vue-tour/dist/vue-tour.css';
// eslint-disable-next-line import/order
import { Icon } from 'leaflet';

// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

Vue.use(Vuelidate);
Vue.use(Buefy);
Vue.use(VueSmoothScroll, {
  duration: 700,
  offset: -75,
});
Vue.use(VueRellax);
Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
  loaderOptions: {
    autoHideBadge: true,
  },
});
Vue.use(VueTour);
Vue.use(VueLazyload);
Vue.prototype.$http = axiosInstance;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');