<template>
  <div id="app">
    <component :is="layout">
      <keep-alive>
        <transition :name="transitionName" mode="out-in">
          <router-view class="child-view"></router-view>
        </transition>
      </keep-alive>
    </component>
  </div>
</template>

<script>
import Layout from '@/components/layout/Layout.vue';
// eslint-disable-next-line no-unused-vars
import moment from 'moment';
import store from './store';
import 'moment/locale/fr';

moment.locale('fr');

export default {
  components: { Layout },
  data() {
    return {
      layout: Layout,
      prevHeight: 0,
      refreshNotification: null,
    };
  },
  created() {
    // initialize service worker update events
    this.$store.dispatch('swModule/initializeSw');
    // add intervall check for token validity, if not valid signout
  },
  mounted() {
    // Trick to avoid input zoom in ios
    // https://stackoverflow.com/questions/9038625/detect-if-device-is-ios/9039885#9039885
    const checkIsIOS = () =>
      // eslint-disable-next-line implicit-arrow-linebreak
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    if (checkIsIOS()) {
      this.addMaximumScaleToMetaViewport();
    }
  },
  methods: {
    addMaximumScaleToMetaViewport() {
      const el = document.querySelector('meta[name=viewport]');

      if (el !== null) {
        let content = el.getAttribute('content');
        // eslint-disable-next-line no-useless-escape
        const re = /maximum\-scale=[0-9\.]+/g;

        if (re.test(content)) {
          content = content.replace(re, 'maximum-scale=1.0');
        } else {
          content = [content, 'maximum-scale=1.0'].join(', ');
        }

        el.setAttribute('content', content);
      }
    },
    openRefreshNotification() {
      this.refreshNotification = this.$buefy.snackbar.open({
        indefinite: true,
        message: `
          <div>
            <div>
              <p>Nouvelle version disponible !</p>
            </div>
          </div>
        `,
        position: 'is-bottom-left',
        actionText: 'Mettre à jour',
        queue: false,
        onAction: () => {
          this.refreshApp();
        },
      });
    },
    refreshApp() {
      store.dispatch('swModule/setRefreshNotification', { value: false });
      store.dispatch('swModule/refreshApp');
    },
  },
  computed: {
    transitionName() {
      return this.$store.getters.transitionName;
    },
    updateExists() {
      return this.$store.getters['swModule/updateExists'];
    },
    isRefreshNotificationOpen() {
      return this.$store.getters['swModule/isRefreshNotification'];
    },
  },
  watch: {
    updateExists: {
      immediate: true,
      handler(val) {
        if (val) {
          this.$store.dispatch('swModule/setRefreshNotification', { value: true });
          if (!this.refreshNotification) this.openRefreshNotification();
        }
      },
    },
    isRefreshNotificationOpen: {
      handler(val) {
        if (!val && this.refreshNotification) {
          this.refreshNotification.close();
          this.refreshNotification = null;
        }
      },
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100vw;
  font-size: 100%;
}
.child-view {
  transition: all .2s cubic-bezier(.55,0,.1,1);
}
.slide-left-enter, .slide-right-leave-active {
  opacity: 0;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
.slide-left-leave-active, .slide-right-enter {
  opacity: 0;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
</style>