<template>

  <div class="layout_container">

    <Navbar v-if="isNavbarDisplayed" />

    <div class="main_container">

      <div class="main_container__content">
        <div class="content">
          <slot />
        </div>
        <Footer v-if="isFooterDisplayed" />
      </div>
    </div>

  </div>

</template>

<script>
import Navbar from '@/components/layout/Navbar.vue';
import Footer from '@/components/layout/Footer.vue';

export default {
  components: {
    Navbar,
    Footer,
  },
  computed: {
    isFooterDisplayed() {
      return this.$store.getters.isFooterDisplayed;
    },
    isNavbarDisplayed() {
      return this.$route.matched.some(({ name }) => (
        name !== 'Restaurant' && name !== 'RestaurantByAlias'
      ));
    },
  },
};
</script>

<style scoped lang="scss">
  .layout_container {
    width: 100%;
  }

  .main_container {
    display: flex;

    .main_container__content {
      width: 100%;

      .content {
        min-height: calc(100vh - 52px);
        margin-bottom: 0;
      }
    }
  }
</style>