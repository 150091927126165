// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import { authSignin, logout, getUser } from '../services/auth.service';

const initialState = {
  user: getUser()?.user || null,
  token: getUser()?.token || '',
  status: '',
  hasSessionExpired: false,
};

export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGOUT = 'LOGOUT';
export const UPDATE_HAS_SESSION_EXPIRED = 'UPDATE_HAS_SESSION_EXPIRED';

const authModule = {
  namespaced: true,
  state: initialState,
  actions: {
    async signin({ commit }, { payload }) {
      commit(AUTH_REQUEST);

      const res = await authSignin(payload);

      commit(AUTH_SUCCESS, {
        user: res.data.user,
        token: res.data.token,
      });
      return res;
    },
    logout({ commit }) {
      commit(LOGOUT);
      logout();
    },
    updateHasSessionExpired({ commit }, { value }) {
      commit(UPDATE_HAS_SESSION_EXPIRED, { value });
    },
  },
  mutations: {
    [AUTH_REQUEST](state) {
      state.status = 'loading';
    },
    [AUTH_SUCCESS](state, { user, token }) {
      state.status = 'success';
      state.token = token;
      state.user = user;
    },
    [AUTH_ERROR](state) {
      state.status = 'error';
    },
    [LOGOUT](state) {
      state.status = '';
      state.token = '';
      state.user = null;
    },
    [UPDATE_HAS_SESSION_EXPIRED](state, { value }) {
      state.hasSessionExpired = value;
    },
  },
  getters: {
    user: (state) => state.user,
    token: (state) => state.token,
    isLoggedIn: (state) => {
      if (!Date.now) {
        // for IE 8
        Date.now = () => new Date().getTime();
      }
      if (state.token) {
        const decoded = jwt_decode(state.token);
        const { exp } = decoded;
        // eslint-disable-next-line no-bitwise
        const currentTimestamp = Date.now() / 1000 | 0;

        // if token exp timestamp < current timestamp token is expired
        return exp > currentTimestamp;
      }
      return false;
    },
    authStatus: (state) => state.status,
    hasSessionExpired: (state) => state.hasSessionExpired,
  },
};

export default authModule;