<template>

  <form @submit.prevent="recaptcha" style="width: 35%; min-width: 300px;">

    <div class="mb-6">

      <div class="mb-6 has-text-centered">
        <p class="">Se connecter</p>
      </div>

      <b-field
        label="Email"
        custom-class="is-small has-text-weight-light has-text-grey"
        :type="{ 'is-danger': $v.email.$error }"
        :message="[
          { 'Email non valide': $v.email.$error },
        ]"
      >
        <b-input
          type="email"
          v-model.trim="$v.email.$model"
          ref="emailInput"
          rounded
        />
      </b-field>

      <b-field
        label="Mot de passe"
        custom-class="is-small has-text-weight-light has-text-grey"
      >
        <b-input
          type="password"
          v-model.trim="$v.password.$model"
          password-reveal
          rounded
        />
      </b-field>

      <b-notification
        type="is-dark"
        aria-close-label="Fermer message d'erreur"
        role="alert"
        closable
        class="mt-4"
        v-model="active"
      >
        {{ error }}
      </b-notification>

      <div>
        <router-link to="/password/verify">Mot de passe oublié ?</router-link>
      </div>

    </div>

    <div>

      <b-button
        label="Se connecter"
        type="is-primary"
        native-type="submit"
        expanded
        ref="loginBtnRef"
        class="is-primary mb-4 mt-4"
        rounded
      />

      <b-button
        label="S'inscrire"
        tag="router-link"
        to="/signup"
        expanded
        rounded
        outlined
      />

      <b-loading v-model="isLoading" />

    </div>

  </form>

</template>

<script>
import { mapGetters } from 'vuex';
import { required, email } from 'vuelidate/lib/validators';

export default {
  data: () => ({
    email: '',
    password: '',
    error: null,
    active: false,
    isLoading: false,
  }),
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },
  methods: {
    async recaptcha() {
      try {
        this.isLoading = true;
        // (optional) Wait until recaptcha has been loaded.
        await this.$recaptchaLoaded();

        // Execute reCAPTCHA with action "login".
        // createAccount landing
        const token = await this.$recaptcha('login');

        // Verify challenge with received token.
        await this.$http.post(`${process.env.VUE_APP_API_URL}/captchas/verify`, { token });
        // Recaptcha success, let's login.
        this.login();
      } catch (error) {
        this.$buefy.snackbar.open({
          indefinite: true,
          type: 'is-danger',
          message: 'Une erreur est survenue.',
        });
        this.isLoading = false;
      }
    },
    async login() {
      if (!this.disabled) {
        const payload = {
          email: this.email,
          password: this.password,
        };

        this.active = false;
        try {
          const redirectUrl = this.$route.query.redirectUrl || '/bo/restaurants';

          await this.$store.dispatch('authModule/signin', { payload });
          this.$router.push({
            path: redirectUrl,
          });
        } catch (err) {
          const { status } = err.response;
          let message = '';

          switch (status) {
            case 429:
              message = 'Nombre d\'essais autorisés dépassé, veuillez réessayer dans une heure.';
              break;
            default:
              message = err.response.data.message;
          }
          this.error = message;
          this.active = true;
          this.$store.dispatch('authModule/logout');
        } finally { this.isLoading = false; }
      }
    },
  },
  computed: {
    ...mapGetters('authModule', [
      'hasSessionExpired',
    ]),
  },
  mounted() {
    const updateHasSessionExpired = this.hasSessionExpired;

    if (updateHasSessionExpired) {
      this.$store.dispatch('authModule/updateHasSessionExpired', {
        value: false,
      });
    }
    this.$refs.emailInput.focus();
  },
};
</script>

<style scoped lang="scss">
  .page-container {
    min-height: calc(100vh - 162px);
  }
  ::v-deep label {
    font-weight: 400 !important;
  }
  ::v-deep .mdi-eye {
    color: hsl(0, 0%, 71%);
  }
</style>